import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "../hooks/useWindowSize";

export const CollectionPreview = ({ collection }) => {
  return (
    <div className="tile is-parent is-4" key={`collection_${collection.id}`}>
      <div className="tile is-child card">
        <div className="card-image">
          <figure className="image">
            <GatsbyImage
              image={collection?.image?.gatsbyImageData}
              alt={collection.handle}
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <div className="level">
              <div className="level-left">
                <p className="subtitle is-3">{collection.title}</p>
              </div>
              <div className="level-right">
                <Link
                  className="button is-small is-ghost"
                  to={`/shop/${collection.handle}`}
                >
                  <span>Explore</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Link>
              </div>
            </div>
            <p>{collection.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FeaturedCarousel = ({ features }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [increment, setIncrement] = useState(1.0);
  const [opacity, setOpacity] = useState(0.0);
  const [transition, setTransition] = useState(false);
  const size = useWindowSize();
  const getCarouselHeight = (s) => {
    if (s.width >= 1492) {
      return 640;
    } else {
      return Math.floor(640 * (s.width / 1492));
    }
  };
  const [carouselHeight, setCarouselHeight] = useState(
    getCarouselHeight({ height: 0, width: 0 })
  );

  useEffect(() => {
    setCarouselHeight(getCarouselHeight(size));
  }, [size]);

  useEffect(() => {
    if (!transition) {
      setTimeout(() => {
        setTransition(true);
      }, 10000);
    }
  }, [transition]);

  useEffect(() => {
    if (transition) {
      setTimeout(() => {
        setOpacity(opacity + increment * 0.1);
      }, 50);
      if (opacity >= 1.0 && increment > 0) {
        setSelectedIndex((selectedIndex + 1) % features.length);
        setIncrement(-1.0);
      }
      if (increment < 0 && opacity <= 0.0) {
        setTransition(false);
      }
    } else {
      setOpacity(0.0);
      setIncrement(1.0);
    }
  }, [transition, selectedIndex, features.length, increment, opacity]);

  if (!features || features.length === 0) {
    return <section className="container"></section>;
  }

  return (
    <section className="container">
      <div
        role="navigation"
        className="hero"
        style={{
          position: "relative",
          height: `${carouselHeight}px`,
          opacity: 20,
        }}
        onClick={() => {
          if (
            features[selectedIndex].data.link.url &&
            features[selectedIndex].data.link.url.length > 0
          ) {
            navigate(features[selectedIndex].data.link.url);
          }
        }}
      >
        {opacity > 0.0 ? (
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              objectFit: "contain",
              width: "100%",
              height: "100%",
              zIndex: 101,
              opacity: opacity,
            }}
          ></div>
        ) : null}
        <GatsbyImage
          alt={features[selectedIndex].data.image.alt}
          image={features[selectedIndex]?.data?.image?.gatsbyImageData}
        />
      </div>
      <div className="control mt-4 has-text-centered">
        {features.map((f, i) => {
          if (i === selectedIndex) {
            return (
              <label className="radio" key={`radio-${i.toString()}`}>
                <input type="radio" checked onChange={() => {}}></input>
              </label>
            );
          } else {
            return (
              <label className="radio" key={`radio-${i.toString()}`}>
                <input
                  type="radio"
                  onClick={() => {
                    setTransition(false);
                    setSelectedIndex(i);
                  }}
                  checked={false}
                  onChange={() => {}}
                ></input>
              </label>
            );
          }
        })}
      </div>
    </section>
  );
};
