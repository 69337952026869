import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { CollectionPreview } from "../utils/home";
import { Carousel } from "react-responsive-carousel";
import { GatsbyImage } from "gatsby-plugin-image";

const HomePage = () => (
  <StaticQuery
    query={graphql`
      query {
        allShopifyCollection {
          edges {
            node {
              id
              description
              handle
              title
              image {
                gatsbyImageData(layout: CONSTRAINED, height: 500)
              }
            }
          }
        }
        allPrismicHomePageCarouselItems(sort: { fields: data___order }) {
          nodes {
            data {
              link {
                url
              }
              image {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const collections = data.allShopifyCollection.edges
        .filter((e) => e.node.image !== null && e.node.handle !== "classes")
        .map((e) => e.node);
      return (
        <Layout>
          <Carousel
            infiniteLoop={true}
            showStatus={false}
            autoPlay={true}
            interval={5000}
          >
            {data.allPrismicHomePageCarouselItems.nodes.map((n, i) => (
              <div key={`feature-${i}`}>
                <figure className="image">
                  {n.data.link.url ? (
                    <Link to={n.data.link.url.replace("https://", "")}>
                      <GatsbyImage
                        alt={n.data.image.alt}
                        image={n.data.image.gatsbyImageData}
                      />
                    </Link>
                  ) : (
                    <GatsbyImage
                      alt={n.data.image.alt}
                      image={n.data.image.gatsbyImageData}
                    />
                  )}
                </figure>
              </div>
            ))}
          </Carousel>
          <section className="container mt-4">
            <p className="title is-4">Collections</p>
            {[...Array(Math.ceil(collections.length / 3)).keys()].map((i) => {
              return (
                <div className="tile is-ancestor" key={`collection-row-${i}`}>
                  {collections.slice(i * 3, i * 3 + 3).map((c) => (
                    <CollectionPreview collection={c} key={`${c.handle}`} />
                  ))}
                </div>
              );
            })}
          </section>
        </Layout>
      );
    }}
  />
);

export default HomePage;
